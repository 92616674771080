<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Flujo de aprobacion">
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
        <Basic-Btn text="Regresar" color="grey lighten-2" icon="mdi-arrow-left" @click="back()" :disabled="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ dateFormat(item.fecha) }}
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, BasicAlert, TableContainer, BasicBtn,  } from "@/components";
import moment from "moment";

export default {
  name: "aprove-flow",
  components: {
    Loader,
    BasicAlert,
    TableContainer,
    BasicBtn,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Usuario", align: "left", sortable: true, value: "usuario" },
      { text: "Estatus", align: "left", sortable: true, value: "estatus" },
      { text: "Comentario", align: "left", sortable: true, value: "comentarios" },
      { text: "Fecha", align: "left", sortable: true, value: "fecha" },
    ],
    items: [],
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
       this.loading = true;
      let request = {
        params: {
          id: this.$route.params.id,
        },
      };
      this.axios
        .get(services.routes.loan + "/flow", request)
        .then((response) => {
          let data = response.data.data;
          this.items = data;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push("/mis-solicitudes");
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped></style>
